<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid class="px-0">
            <v-flex xs12 sm12 md12 >
                <ck-calendar
                    :show-date="showDate"
                    :startingDayOfWeek="1"
                    weekdayNameFormat="long"
                    class="theme-default"
                    :birthdays="birthdays"
                    :showBirthdayIcon="true"
                    >
                    <ck-calendar-header
                        slot="header"
                        slot-scope="{ headerProps }"
                        :header-props="headerProps"
                        @input="setShowDate"
                    />
                </ck-calendar>
            </v-flex>
    </v-container>
</div>
</template>

<script>
import CkCalendar from "@/components/calendar/CkCalendar.vue";
import CkCalendarHeader from "@/components/calendar/CkCalendarHeader.vue";
import Utils from "../util/utils";
import GetUrlImg from "@/plugins/Urlimagen";
import empleadosApi from '../api/empleados';

export default {
    components: {
        CkCalendar,
        CkCalendarHeader,
    },
    data() {
        return {
            loginData   : this.$session.get('usuario'),
            showDate    : new Date(),
            year        : null,
            birthdays   : [],
            baseUrl     : GetUrlImg.getURL(),
        };
    },
    methods: {
        init() {
            this.year       = this.showDate.getFullYear();
            let dateResult  = this.getFirstAndLastDate();

            this.getBirthdays(dateResult.start, dateResult.end);
        },
        setShowDate(d) {
            this.showDate   = d
            this.year       = this.showDate.getFullYear();
            let dateResult  = this.getFirstAndLastDate();

            this.getBirthdays(dateResult.start, dateResult.end);
        },
        getFirstAndLastDate() {
            let month           = this.showDate.getMonth() + 1;
            let monthStr        = month.toString().length == 1 ? "0" + month.toString() : month.toString();
            let initialdate     = `${this.year}-${monthStr}-01`;
            let finalDate       = new Date(this.year, monthStr, 0);

            return {start: initialdate, end: Utils.date_format(finalDate)};
        },
        getBirthdays(min, max) {
            let rol             = this.loginData.rol.name;
            var cliente_id      = null;
            var empresa_id      = null;
            var sucursal_id     = null;

            if(rol == "admin") {
                cliente_id = this.loginData.cliente_id;
            } else if(rol == "admin-empresa") {
                cliente_id  = this.loginData.cliente_id;
                empresa_id  = this.loginData.empresa_id;
            } else {
                cliente_id  = this.loginData.cliente_id;
                empresa_id  = this.loginData.empresa_id;
                sucursal_id = this.loginData.sucursal_id;
            }

            let param = {
                rol_name    : this.loginData.rol.name,
                usuario_id  : this.loginData.id,
                activo      : true,
                cliente_id  : cliente_id,
                empresa_id  : empresa_id,
                sucursal_id : sucursal_id,
                min_fecha   : min,
                max_fecha   : max
            };

            this.birthdays = [];
            empleadosApi.cumpleaniosEmpleado(param).then((response) => {
                response.cumpleanios.forEach(element => {
                    let fnacimiento = element["fecha_nacimiento"].split("-");

                    let item = {
                        id          : element["id"],
                        startDate   : `${this.year}-${fnacimiento[1]}-${fnacimiento[2]}`,
                        title       : element["empleado"],
                        subtitle    : element["nombre_depto"],
                        photo       : this.baseUrl + element["foto"],
                        gender      : element["sexo"],
                    };

                    this.birthdays.push(item);
                })
            }).catch(err => console.log(err));
        },
    },
    created() {
        this.init();
    },
}
</script>

<style scoped>
    @import "/static/css/default.css";
</style>